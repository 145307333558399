<template lang="pug">
  div
    .sidebar-layout__toolbar
      //- button.btn.btn--brand(
        :class="{ 'btn--disabled': false }")
        font-awesome-icon.mr-1(
          size="sm"
          icon="filter")
        span {{ isMobile ? 'base.filter' : 'actions.add_filter' | translate }}

      v-dialog(
        max-width="450"
        v-model="modals.showFilters")
        filters-modal(
          @close="modals.showFilters = false")

    navigation
    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      final-product-table

    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      final-product-table-mobile

</template>

<script>
import adaptationMixin from '@/mixins/adaptation.mixin'

import Navigation from '../common/Navigation'

import FiltersModal from './modals/FiltersModal'

const FinalProductTable = () => import('./FinalProductTable')
const FinalProductTableMobile = () => import('./FinalProductTableMobile')

export default {
  name: 'StorageFinalProduct',

  mixins: [adaptationMixin],

  components: {
    Navigation,
    FinalProductTable,
    FinalProductTableMobile,
    FiltersModal
  },

  data: () => ({
    modals: {
      showFilters: false
    }
  })
}
</script>

<style lang="scss">
</style>
